.page__header.thanks-header .confetti-pop {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 50vw;
  max-height: none;
}

@media (max-width: 991px) {
  .page__header.thanks-header .confetti-pop {
    max-width: 100vw;
    max-height: 100vw;
    height: 100vw;
    width: auto;
    top: 0;
  }
}

.page__header.thanks-header {
  padding-top: 30px;
  background-size: cover;
  background-repeat: no-repeat;
  max-height: 100vh;
}

.page__header.thanks-header .header__img {
  position: absolute !important;
  display: flex;
  max-width: 420px;
  position: fixed;
  bottom: 0;
  z-index: 0;
}

.page__header.thanks-header .header-content {
  top: 50%;
  position: fixed;
  max-height: 98vh;
  overflow-y: auto;
}

@media (max-width: 991px) {
  .page__header.thanks-header .header-content {
    top: 40% !important;
    bottom: auto !important;
    position: relative !important;
    padding: 0 !important;
    display: flex;
    justify-content: center;
    transform: none !important;
  }
  .page__header.thanks-header h2 {
    margin: 0 0 15px;
  }
  .page__header.thanks-header .header__img img {
    left: 97px !important;
    width: 307px !important;
  }
  .page__header.thanks-header .header__img img {
    bottom: -93px;
  }
}

@media (min-width: 992px) {
  .page__header.thanks-header .header-content {
    position: relative;
  }
}



.header-content h3,
.header-container h3 {
  color: #fff;
  font-size: 18px;
}

.resultaten-list {
  color: #fff;
  padding: 30px 0;
  color: var(--red);
  text-align: left;
}

.resultaten-list ul li {
  display: flex;
}

.page__header.thanks-header .header-content {
  height: auto !important;
  padding: 45px 0 !important;
}





.resultaat-block {
  padding: 20px 30px;
  background: #eee;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  position: relative;
  z-index: 10;
}

.resultaat-block p {
  margin: 0;
  color: #000;
  text-align: left;
  font-size: 14px;
}

.resultaten-modal {
  padding: 30px;
  position: fixed;
  left: 50%;
  top: 50%;
  width: 100vw;
  height: 100vh;
  transform: translateY(calc(-50% + 100vh));
  background: #fff;
  color: #000;
  max-width: 500px;
  height: auto;
  text-align: left;
  border-radius: 12px;
  max-height: 80vh;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  z-index: 99;
}

.resultaten-modal ul li:not(:last-child) {
  margin-bottom: 20px;
}

.resultaten-modal img {
  position: fixed;
  right: 20px;
  top: 20px;
  cursor: pointer;
  width: 30px;
}

.resultaten-modal h4 {
  color: #000;
  font-size: 21px;
  font-weight: bold;
  line-height: 0;
  margin-bottom: 30px;
  margin-top: 10px;
  text-shadow: none;
}

.resultaten-modal p {
  margin-bottom: 15px;
  color: #000;
}

.resultaten-modal.shown {
  transform: translateX(-50%) translateY(-50%);
}

.resultaat-block .read-more-btn {
  font-weight: bold;
  font-size: 16px;
  color: #000;
  text-align: left;
  margin-top: 20px;
  text-decoration: underline;
  cursor: pointer;
}



.modal-overlay {
  position: absolute;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0,0,0,0.5);
  z-index: 10;
  backdrop-filter: blur(8px);
  transform: translateX(9999%);
  display: none;
}

.modal-overlay.shown {
  transform: translateX(0%);
  display: block;
}



.header-container {
  height: auto;
  overflow-y: auto;
  text-align: center;
  max-width: 600px;
  margin: 0 auto;
  padding-top: 30px;
  padding-bottom: 60px;
  position: relative;
  z-index: 5;
}

@media (max-width: 991px) {
  .header-container {
    height: auto;
    overflow-y: auto;
    text-align: center;
    max-width: 600px;
    margin: 0 auto;
    padding-top: 60px;
    padding-bottom: 0;
    min-height: 750px;
  }
}

/* body {
  max-height: 100vh;
  overflow: hidden;
} */

.page__header.thanks-header {
  height: auto !important;
  display: block !important;
}