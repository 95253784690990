body {
  background: #eee;
  font-family: 'Sarabun', sans-serif;
}

/* USPs */
.usps {
  display: flex;
  gap: 40px;
  justify-content: space-between;
  padding: 30px 0 60px;
  flex-wrap: wrap;
}

.usp-item {
  display: flex;
  flex-direction: column;
  gap: 15px;
  text-align: left;
  align-items: flex-start;
  padding: 20px 27.5px 30px;
  box-shadow: none;
  background: linear-gradient(180deg, rgba(255,255,255,1), rgba(255,255,255,0));
  color: #333;
  flex: auto;
  width: calc(33.3% - 40px);
}

.big-title {
  color: #FFF;

text-align: center;
text-shadow: 0px 4px 174.5px #000;
font-size: 60px;
font-style: normal;
font-weight: 700;
line-height: normal;
letter-spacing: -3.6px;
line-height: 110%;
margin-bottom: 20px;
}

@media (max-width: 991px) {
  .big-title {
    text-align: left !important;
    font-size: 38px;
    letter-spacing: -1.6px;
  }
}

.usp-item p {
  font-size: 18px;
  color: #333;
}

@media (max-width: 991px) {
  .usps {
    flex-direction: column;
    gap: 20px;
  }
  .usp-item {
    width: 100%;
    flex: auto;
    display: flex;
    flex-direction: column;
    text-align: left;
    gap: 25px;
  }
  .usp-item svg {
    width: 50px;
    max-width: 50px;
    min-width: 50px;
  }
}

.usp-item h5 {
  font-size: 21px;
  text-align: left;
}

/* Science */
.science-item {
  display: flex;
  flex-direction: column;
  padding: 20px 20px;
  border-radius: 6px;
  box-shadow: 0 0 8px rgba(255,255,255,0.9);
  background-color: rgba(255,255,255,0.65);
}

.science-item h5 {
  font-size: 24px;
  line-height: 100%;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media (max-width: 991px) {
  .science-item h5 {
    line-height: 120%;
  }
}

.science-item h5 svg {
  max-width: 20px;
  height: auto;
  opacity: 0.25;
  transform: rotate(0deg);
  transition: transform .5s ease;
}

.science-item.active h5 svg {
  transform: rotate(180deg);
  transition: transform .5s ease;
}

.science-item .schema-item {
  margin: 20px 0 12px;
  display: flex;
  gap: 5px;
  font-weight: 600;
  flex-direction: column;
}

.science-item p {
  margin: 12px 0 12px;
}

.science-item .science-source {
  font-size: 14px;
  font-weight: 300;
  display: flex;
  font-style: italic;
}

.science-item .science-body {
  max-height: 0px;
  overflow: hidden;
  transition: max-height .5s ease;
}

.science-item.active .science-body {
  max-height: 400px;
  transition: max-height .5s ease;
}

.science-container {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

/* Het schema */
.schema-list {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.schema-list h5 {
  font-size: 24px;
  margin-bottom: 0px;
}

.schema-list span {
  font-size: 16px;
}

/* Gifts */
.gift-item img {
  width: 100%;
  max-height: 485px;
  object-fit: cover;
  object-position: top;
}

.gift-item {
  position: relative;
}

.gift-item p {
  font-size: 14px;
  text-align: center;
  margin-top: 10px;
}

.gift-item .sticker-free {
  position: absolute;
  right: -65px;
  top: 60px;
  border-radius: 50%;
  background-color: var(--red);
  color: #fff;
  box-shadow: 0 0 8px rgba(0,0,0,0.25);
  width: 140px;
  height: 140px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transform: rotate(15deg);
}

@media (max-width: 991px) {
  .gift-item .sticker-free {
    right: auto;
    top: auto;
    bottom: -15px;
    left: -15px;
  }
}

.gift-item .sticker-free > span {
  line-height: 100%;
  position: relative;
  top: -10px;
}

.gift-item .sticker-free > span:nth-child(2) {
  font-weight: bold;
  font-size: 32px;
}

@media (max-width: 991px) {
  .gift-item {
    display: flex;
    gap: 30px;
  }
  .gift-item img {
    width: 180px;
    min-width: 180px;
    max-height: none;
    object-fit: cover;
    object-position: center;
    height: 250px;
  }
  .gift-item p {
    text-align: left;
  }
}




/* Header */
.header {
  position: relative;
  padding: 0;
  height: auto;
  border-bottom: 1px solid rgba(0,0,0,0.25);
  background: #221e1d;
  overflow: hidden;
}

@media (max-width: 991px) {
  .header {
    padding: 30px 0 0 !important;
    height: 85vh;
    position: relative;
    border-bottom: 1px solid rgba(0,0,0,0.25);
  }
}

.header img {
  position: absolute;
  bottom: 0;
  right: -90px;
  max-height: 400px;
}

@media (max-width: 991px) {
  .header img {
    position: absolute;
    bottom: 0;
    right: 0;
    max-height: 340px;
    opacity: 0.35;
  }
}

.header-inner {
  padding: 120px 0 90px;
  align-items: center;
}

@media (max-width: 991px) {
  .header-inner {
    padding: 60px 0 30px;
  }
}

.header-inner h5 {
  font-size: 21px;
  text-transform: uppercase;
  background: #aea9a8;
  padding: 3px 12px;
  width: auto;
  margin-bottom: 15px;
  background: transparent;
  color: var(--red);
}

@media (max-width: 991px) {
  .header-inner h5 {
    font-size: 18px;
    text-transform: uppercase;
    background: #aea9a8;
    padding: 3px 12px;
    width: fit-content;
    margin-bottom: 15px;
    background: transparent;
    color: var(--red);
  }
}

.header-inner h1 {
  color: #fff;
  font-family: "Sarabun", sans-serif;
  text-transform: uppercase;
  letter-spacing: -1px !important;
  line-height: 110%;
  font-size: 48px;
}
.header-inner h4 {
  font-size: 24px;
  color: #fff;
}
.header-inner p {
  color: #aea9a8;
}

@media (max-width: 991px) {
  .header-inner h1 {
    font-size: 40px;
  }
  .header-inner h4 {
    font-size: 16px;
    color: #000;
  }
}

.header-inner {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

/* Sticky ATC */
.sticky-atc {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 999;
}

.sticky-atc a {
  display: flex;
  gap: 6px;
  align-items: center;
  border-radius: 0 !important;
  padding: 15px 0 !important;
}

.sticky-atc svg {
  max-width: 20px;
  height: auto;
}



/* offer */
@media (max-width: 991px) {
  .pakket-section .offer-img {
    max-height: 325px;
    object-fit: cover;
    object-position: bottom;
  }
}


/* .pakket-section {
  background-color: #eee;
} */
/* .pakket-section {
  background-color: #161616;
  color: #fff;
}

.pakket-section h1 {
  color: #fff;
}

.pakket-section .section-heading.dark-heading p {
  color:  #fff;
}

.pakket-section p {
  color: #fff;
}

.pakket-section .step-lines {
  border-color: #484848;
} */


/* Featured review */
.featured-review {
  background: rgba(0,0,0,0.05);
  padding: 35px 0;
}

.featured-review img {
  box-shadow: 0 0 20px rgba(0,0,0,0.5);
}

.featured-review h3 {
  margin: 0 0 15px;
  font-size: 32px;
}

.featured-review p {
  font-style: italic;
  font-size: 21px;
  line-height: 170%;
}

@media (max-width: 991px) {
  .featured-review p {
    font-size: 16px;
  }
}

.featured-review .reviewee-name {
  font-weight: bold;
  margin-top: 25px;
  display: flex;
  font-size: 32px;
}

.coach-section {
  padding: 90px 0;
}

@media (max-width: 991px) {
  .coach-section p {
    font-size: 16px;
  }
  .coach-section {
    padding-bottom: 30px;
  }
}



/* Waarom */
.waarom-section .verwachten-item {
  padding: 30px;
  background: linear-gradient(180deg, #fff, transparent);
  margin-bottom: 30px;
}

.waarom-section h3 {
  font-size: 24px;
}



@media (max-width: 991px) {
  p, li {
    font-size: 15px !important;
    line-height: 24px !important;
  }
}


.swiper-outer .swiper-wrapper {
  -webkit-transition-timing-function:linear!important; 
  -o-transition-timing-function:linear!important;
  transition-timing-function:linear!important; 
}

.swiper-outer .swiper-slide {
  width: 400px;
  height: auto;
}

.swiper-outer .swiper-slide .review-item {
  height: 100%;
}




.continuous-slider-container .swiper {
  margin-bottom: 20px;
}

.reviews-section {
  background: #221e1d;
  overflow: hidden;
}

.reviews-section .reviews-heading {
  text-align: center;
  border: none;
}

.reviews-section .reviews-heading h2 {
  font-size: 70px;
  margin: 0 0 10px;
}

.reviews-section .reviews-heading h4 {
  font-size: 42px;
  color: #fff;
  text-decoration: none;
}

.reviews-section .stars-rating {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
}

.review-item {
  background-color: #333;
  border: none;
  padding: 20px;
}

.review-item h5 {
  font-size: 20px;
  color: #fff;
}

.review-item p {
  font-size: 15px;
  color: #aea9a8;
}

.review-item .review-name {
  color: #fff;
}








/* .voorwie-item {
  background-image: url(../images/red-bullet.png);
  background-repeat: no-repeat;
  background-position: left 7px;
  background-size: 20px;
  padding-left: 30px;
  margin-bottom: 30px;
} */


.section-heading {
  max-width: 590px;
}

.voorwie-section h2 {
  font-size: 72px;
  letter-spacing: -2px;
  font-weight: 900;
  text-transform: uppercase;
}

@media (max-width: 991px) {
  .voorwie-section h2 {
    font-size: 42px;
  }
}

.voorwie-item p {
  font-size: 21px;
  line-height: 170%;
  display: flex;
  gap: 15px;
  margin-bottom: 35px;
}

@media (max-width: 991px) {
  .voorwie-item p {
    font-size: 16px;
    margin-bottom: 20px;
  }
}

.voorwie-item p img {
  width: 30px;
  height: 30px;
  position: relative;
  top: 2px;
}

.voorwie-section .bottom-text p {
  font-size: 24px;
  font-weight: bold;
}

.vimeo-overlay {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  border-radius: 8px;
  cursor: pointer;
}

@media (max-width: 991px) {
  .krijg-list li {
    font-size: 16px;
    display: flex;
    gap: 7px;
    margin-bottom: 6px;
  }
}

.icon-container {
  border-radius: 50%;
  width: 60px;
  height: 60px;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0.6;
}

.vimeo-video {
  display: flex;
  justify-content: center;
}

.vimeo-video iframe  {
  border-radius: 8px;
  width: 400px;
  height: 650px;
  max-width: 90vw;
}



.text-image-section {
  padding: 60px 0 60px;
  border-bottom: 1px solid rgba(0,0,0,0.125);
}



.footer {
  padding: 55px 0 200px !important;
}

@media (max-width: 991px) {
  .footer {
    padding: 30px 0 55px !important;
  }
}

/* Garantie */
.garantie-section {
  background: var(--dark-gray) !important;
  padding: 60px;
} 

.garantie-section h2,
.garantie-section p {
  color: #fff !important;
} 

@media (max-width: 991px) {
  .garantie-section {
    background: var(--dark-gray) !important;
    padding: 45px 30px;
  } 
  .garantie-section h2 {
    font-size: 32px !important;
  } 
}


.fb-section {
  padding: 30px 0;
}


.prijs-card ul li b {
  color: #000;
}

.prijs-card ul {
  margin-top: 0 !important;
  border-left: none !important;
  padding-left: 0px;
}

.prijs-card p {
  color: #000;
}

.prijs-card h4 {
  color: #000;
  font-size: 32px;
  font-weight: 600 !important;
}
.prijs-card h5 {
  color: #000;
  font-size: 18px;
  font-weight: 400 !important;
}

/* Wat je krijgt */
.wat-je-krijgt-section h4 {
  color: #000;
  text-transform: uppercase;
  font-size: 32px;
}

.wat-je-krijgt-section h5 {
  font-size: 20px;
  margin: 20px 0 7px;
}

.wat-je-krijgt-section ul {
  padding-left: 20px;
}

.wat-je-krijgt-section ul li {
  list-style: none;
  font-size: 16px;
  margin-bottom: 5px;
}

@media (max-width: 991px) {
  .wat-je-krijgt-section.more-section {
    padding-bottom: 160px !important;
  }
  .wat-je-krijgt-section.more-section .container > .row {
    margin-top: -160px !important;
    padding-top: 90px;
  }
}

.krijgt-cta {
  background-color: var(--red) !important;
  color: #fff;
  padding: 75px 0 60px !important;
}
.krijgt-cta p {
  color: #fff;
}
.krijgt-cta .row {
  padding-top: 0 !important;
}

@media (max-width: 991px) {
  .krijgt-cta {
    padding-top: 90px !important;
    margin-top: -90px;
  }
}