.review-card {
  display: flex;
  flex-direction: column;
  color: #000;
  background: #fff;
}

.review-card p {
  color: #000;
  letter-spacing: .35px;
  font-size: 16px;
}

.review-card .review-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.review-avatar {
  display: flex;
  align-items: center;
  gap: 12px;
}

.review-date {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #eee;
  padding-bottom: 20px;
  margin-bottom: 20px;
}

.review-date .date {
  font-weight: 300;
  text-transform: uppercase;
  color: #000;
  font-size: 20px;
}

.review-avatar img {
  width: 40px !important;
  height: 40px !important;
  margin: 0 !important;
}

.review-avatar h5 {
  font-weight: 600;
  margin: 0 !important;
  font-size: 18px !important;
  color: #000 !important;
}

.review-date h6 {
  font-weight: 300;
  margin: 0;
  text-transform: uppercase;
  color: #000;
}