.confetti-pop {
  position: fixed;
  z-index: 0;
  left: 0;
  width: 100%;
  height: 100%;
  top: 0;
}

.finished-container {
  color: #fff;
}

body {
  max-height: none !important;
}

@media (max-width: 991px) {
  body {
    overflow-y: auto !important;
  }
  .header-container {
    overflow: hidden !important;
    height: 100% !important;
  }
}





/* Finished */
.usp-bar {
  padding: 70px 0;
}

.usp-bar h5,
.usp-bar p 
 {
  color: #fff;
}

.header-section {
  padding: 70px 0;
}

.header-section h1 {
  color: #fff !important;
  font-size: 32px !important;
}

.header-section p {
  color: #fff;
}

.usp-bar-red {
  background: var(--red);
  padding: 35px 0 45px;
  text-align: center;
}

.usp-bar-red h3 {
  color: #fff;
}
.usp-bar-red p {
  color: #fff;
}

@media (max-width: 991px) {
  .usp-bar-red h3 {
    font-size: 24px;
  }
}



.usp-bar h5 {
  font-size: 24px;
  margin-bottom: 15px;
}



.text-image-block {
  padding: 70px 0;
  background-color: #333;
}

.text-image-block h5 {
  font-size: 40px;
  color: #fff;
  margin-bottom: 20px;
}

@media (max-width: 991px) {
  .text-image-block h5 {
    font-size: 32px;
  }
}

.text-image-block p {
  color: #fff;
}



.hoe-werkt-het {
  padding: 70px 0;
}

.hoe-werkt-het .number {
  font-size: 72px;
  color: #fff;
  margin-bottom: 20px;
  font-weight: bold;
}

.hoe-werkt-het h5 {
  font-size: 32px;
  color: #fff;
  margin-bottom: 20px;
}

.hoe-werkt-het p {
  color: #fff;
}


.pakket-section {
  padding: 70px 0;
}

@media (max-width: 991px) {
  .pakket-section {
    background-size: cover;
    background-position: center;
  }
}

.pakket-section h3 {
  color: #fff;
  margin-bottom: 45px;
  font-size: 50px;
  text-align: center;
}

.pakket-section h4 {
  font-size: 60px;
}

@media (max-width: 991px) {
  .pakket-section h3 {
    color: #fff;
    margin-bottom: 10px;
    font-size: 32px;
    text-align: center;
  }
  .pakket-section h4 {
    font-size: 40px;
    margin-bottom: 30px;
  }
}



.faq-item {
  border-color: #fff;
}
.faq-section h3 {
  color: #fff;
}
.faq-section .faq-body p {
  color: #fff;
}




.timer-section {
  padding: 70px 0;
  background: var(--dark-gray);
  display: flex;
}
.timer-section .prijs-timer {
  margin: 0;
}
.timer-section .timer-container {
  display: flex !important;
  flex-direction: column;
  align-items: center;
  gap: 30px;
}
.timer-section .timer {
  display: flex !important;
  position: relative;
  top: 0;
  left: 0;
  transform: none;
  margin: 0 !important;
  justify-content: center;
}


@media (max-width: 991px) {
  .header-container {
    height: auto !important;
    min-height: auto;
  }
}








.label-title {
  background: #fff;
  color: #000;
  padding: 10px 30px;
  border-radius: 8px;
  text-align: center;
  width: auto;
  margin: 0 auto;
  display: flex;
  justify-content: center;
}


.prijs-card h4 {
  font-size: 32px;
}






.before-after-section {
  background: #333;
  padding: 60px 0;
}

.before-after-section h3 {
  color: #fff;
}