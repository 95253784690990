.dienst-list {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

@media (max-width: 991px) {
  .dienst-list {
    gap: 5px;
  }
}

.dienst-item {
  display: flex;
  align-items: flex-start;
  margin-bottom: 0;
}

.dienst-item svg {
  margin-right: 25px;
  width: 32.5px;
  min-width: 32.5px;
  height: 32.5px;
  position: relative;
  top: 5px;
}

.diensten h6::before {
  display: none;
}

.diensten h6::after {
  display: none;
}

.dienst-content p {
  margin-top: 5px;
  color: #fff;
}

.dienst-item .dienst-content h5 {
  color: #fff;
  font-weight: 700;
  font-size: 24px;
  line-height: 45px;
}

.diensten h6 {
  color: #251E20;
}

@media (max-width: 991px) {
  .dienst-item {
    margin-bottom: 7.5px;
  }
  .dienst-item .dienst-content h5 {
    font-size: 20px;
    line-height: 32px;
  }
}

.dienst-heading {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}

.dienst-content {
  cursor: pointer;
}

.dienst-content p {
  max-height: 0px;
  opacity: 0;
  transition: opacity .5s ease;
}

.dienst-content.active p {
  max-height: 1000px;
  opacity: 1;
  transition: opacity .5s ease;
}

.dienst-content.active {
  transform: rotate(0deg);
  transition: transform .5s ease;
}

.dienst-content.active svg {
  transform: rotate(180deg);
  transition: transform .5s ease;
}