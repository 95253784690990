.mantine-Text-root {
  line-height: 170% !important;
}

h1.mantine-Text-root {
  line-height: 100% !important;
}

.header-review h1.mantine-Text-root {
  line-height: 34px !important;
}

.button {
  padding: 14px 36px 16px;
}