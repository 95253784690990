.faq-section {
  background-color: #eee;
}

.faq-section h2 {
  color: #333; 
}

.faq-item {
  display: flex;
  flex-direction: column;
  padding: 25px 0;
  border-bottom: 1px solid rgba(0,0,0,0.1) !important;
}

.faq-item .faq-heading {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}

.faq-item .faq-heading svg {
  width: 20px;
  height: auto;
  position: relative;
  top: 5px;
}

.faq-item .faq-body {
  max-height: 0px;
  overflow: hidden;
  transition: max-height .5s ease;
}

.faq-item .faq-body p {
  padding-top: 10px;
  width: 100%;
  color: #000 !important;
}

.faq-item.active .faq-body {
  max-height: 1000px;
  transition: max-height .5s ease;
}

.faq-item h3 {
  font-size: 24px;
  color: #333 !important;
}

@media (max-width: 991px) {
  .faq-item h3 {
    font-size: 18px;
  }
  .faq-item {
    padding: 20px 0;
  }
  .faq-item .faq-body p {
    font-size: 16px;
  }
}

.prijs-card {
  margin-bottom: 0;
}


/* Resultaten */
.resultaten-section {
  padding-top: 90px;
  padding-bottom: 60px;
  background-color: rgba(0,0,0,0.045);
}


/* Stappen */
.stappen-section {
  padding: 70px 0;
  border-bottom: 1px solid rgba(0,0,0,0.1);
}

.stappen-section h4 {
  color: #333;
}

@media (max-width: 991px) {
  .stappen-section h4 {
    font-size: 52px;
  }
}


/* Vergelijking */
.vergelijking-section {
  padding: 70px 0;
  border-bottom: 1px solid rgba(0,0,0,0.1);
}

.vergelijking-section img {
  width: 20px;
}

.vergelijking-section .list-container {
  display: flex;
  gap: 45px;
  justify-content: center;
  margin: 30px 0;
}

.vergelijking-section .list-container h4 {
  font-size: 32px;
  color: #333;
}

.vergelijking-section .list-container h5 {
  font-size: 20px;
}

.vergelijking-section .title-container {
  display: flex;
  align-items: flex-end;
  height: 50px;
}

.vergelijking-section .list-container .list {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.vergelijking-section .list-container .list ul {
  display: flex;
  flex-direction: column;
  gap: 6px;
}

.vergelijking-section .list-container .list li {
  display: flex;
  gap: 8px;
  justify-content: flex-start;
  align-items: center;
}
.vergelijking-section .list-container .list li img {
  position: relative;
  top: 1.5px;
}
.vergelijking-section .list-container .list li .title {
  display: flex;
  align-items: center;
  gap: 10px;
}

@media (max-width: 991px) {
  .vergelijking-section .list-container {
    gap: 20px;
    margin: 0px 0;
    flex-direction: column;
  }
  .vergelijking-section .list-container .list {
    width: 100%;
  }
  .vergelijking-section .list-container .list ul {
    gap: 6px;
  }

  .vergelijking-section .list-container .list li {
    display: flex;
    gap: 6px;
    font-size: 16px;
    font-weight: 400;
  }
  .vergelijking-section .list-container .list li .title {
    display: flex;
    align-items: center;
    gap: 6px;
  }

  .vergelijking-section .list-container .list li .desc {
    font-size: 14px;
    font-weight: 400;
  }
  .vergelijking-section img {
    width: 16px;
  }
  .vergelijking-section .list-container h4 {
    font-size: 24px;
    color: #333;
  }
  
  .vergelijking-section .list-container h5 {
    font-size: 18px;
  }

  .vergelijking-section .section-heading.centered {
    text-align: center;
  }
}


/* Offer */
#prijzen {
  background: rgba(0,0,0,0.045);
}

.pakket-section {
  background: #f7f7f7;
}

.pakket-section h3 {
  color: #333 !important;
}

.prijs-card h4,
.prijs-card .pricing span,
.prijs-card p,
.prijs-card ul li {
  color: #333;
}

.prijs-card ul {
  border-color: rgba(0,0,0,0.1);
  border-width: 1px;
}


/* Review */
.review-item p,
.review-item h5 {
  color: #333;
}

.review-item .review-name .verified-badge {
  color: #999;
}

.review-item .review-name {
  color: #000;
}

.reviews-heading h4 {
  color: #333;
}

.stars-rating {
  color: #000;
}


/* Resultaten */
.resultaten-img img {
  border-radius: 18px;
  height: 100%;
}


/* Waarom */
.waarom-section {
  padding: 70px 0;
}

.waarom-section h3 {
  margin: 20px 0 5px;
}

@media (max-width: 991px) {
  .waarom-section h3 {
    font-size: 24px;
  }
}



.reviews-section {
  padding-bottom: 0 !important;
}



.klarna-price span {
  display: flex;
  align-items: center;
  gap: 8px;
  font-style: italic;
  font-size: 14px;
}


.betalen-desc {
  font-weight: 600;
  color: #555;
}

.paymentmethods_row {
  display: flex;
  gap: 4px;
  justify-content: center;
  margin-top: 6px;
}