@import url('https://fonts.googleapis.com/css2?family=Gothic+A1:wght@300;400;500;700;900&family=Sarabun:wght@100;300;400;500;600;700;800&display=swap');

:root {
  /* --red: #E00A18; */
  --red: #CC0C19;
  --dark-gray: #251E20;
  --light-gray: #D9D9D9;
}

/* Reset default styles */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* Set some global styles */
html {
  scroll-behavior: smooth;
}

body {
  font-family: 'Sarabun', sans-serif;
  font-size: 16px;
  line-height: 1.5;
  color: var(--dark-gray);
  background: #fff;
}

a {
  color: #333;
  text-decoration: none;
}

ul {
  list-style: none;
}

/* Header styles */
nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  margin-top: 15px;
}

.menu {
  display: flex;
  justify-content: flex-end;
  list-style: none;
  margin: 0;
  padding: 0;
}

.menu li {
  margin-left: 40px;
}

.menu li a:not(.button) {
  font-weight: 500;
  font-size: 18px;
  color: #fff;
}

@media (max-width: 991px) {
  .menu-container {
    display: none;
    position: fixed;
    left: 0;
    top: 0;
    padding: 20px;
    background: rgba(255,255,255,1);
    height: 100vh;
    min-height: 100vh;
    width: 80vw;
    z-index: 10;
  }
  .menu-container .menu {
    flex-direction: column;
    justify-content: flex-start;
  }
  .menu-container .menu li {
    margin-left: 0;
  }
  .menu-container .menu li a {
    color: var(--dark-gray);
  }

  nav .logo {
    padding: 0 20px;
  }
  nav .logo a {
    display: flex;
    justify-content: center;
  }
  nav .logo img {
    max-width: 70%;
  }
}

/* Page header */
.page__header {
  padding-top: 120px;
  background-size: cover;
  background-repeat: no-repeat;
}

.page__header h1 {
  color: #fff;
  font-weight: 700;
  font-size: 70px;
  margin: 0 0 35px;
  line-height: 100%;
}

.page__header span {
  color: #fff;
  font-weight: 500;
  font-size: 42px;
  position: relative;
}

.header__img {
  position: relative;
  display: flex;
  max-width: 420px;
}

.header__img img {
  object-fit: contain;
}

.page__header span::after {
  content: '';
  position: absolute;
  right: -30px;
  top: 55%;
  transform: translateY(-50%) translateX(100%);
  width: 100%;
  height: 2px;
  background: #fff;
}

.page__header p {
  font-weight: 400;
  font-size: 18px;
  color: #fff;
}

.page__header .header__content {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.page__header h5 {
  color: #fff;
  font-weight: 700;
  font-size: 32px;
  margin: 0 0 15px;
  line-height: 100%;
}

@media (max-width: 991px) {
  .page__header {
    padding-top: 90px;
    padding-bottom: 90px;
    overflow: hidden;
  }
  .page__header h1 {
    font-size: 40px;
    margin: 0 0 20px;
    line-height: 117.5%;
    text-shadow: 0 2px 9px rgba(0,0,0,.5);
  }
  .page__header span {
    font-size: 20px;
  }
  .page__header p {
    font-size: 16px;
    line-height: 155%;
  }
  .page__header .header__content {
    margin-left: auto;
    bottom: 0;
    padding-bottom: 30px;
    padding-left: 20px;
    /* background: linear-gradient(0deg, var(--red), 80%, transparent); */
    width: 100vw;
    left: -10px;
    position: relative;
    padding-right: 30px;
  }
  .page__header .header__content a {
    display: none;
  }
  .header__content-container {
    position: absolute;
    bottom: 0;
    overflow: hidden;
  }
  .header__img {
    height: calc(100vh - 100px);
  }
  .header__img img {
    position: relative;
    left: 0;
    top: 0;
    height: auto;
    width: 436px !important;
    transform: translateX(-17%);
  }
}

/* Buttons */
.button {
  padding: 8px 24px 10px;
  border: 1px solid var(--light-gray);
  background: var(--light-gray);
  color: var(--dark-gray);
  font-weight: 500;
  font-size: 18px;
  text-align: center;
  font-family: 'Sarabun';
  border-radius: 6px;
  transform: scale(1);
  transition: transform .5s ease;
}

.button:hover {
  cursor: pointer;
  transform: scale(1.025);
  transition: transform .5s ease;
}

.button.button-black {
  background: var(--dark-gray);
  color: #fff;
  border-color: var(--dark-gray);
}

.button.button-red {
  background: var(--red);
  color: #fff;
  border-color: var(--red);
}

/* Typography */
body, p {
  font-weight: 400;
  font-size: 18px;
}

h2 {
  font-weight: 700;
  font-size: 36px;
  line-height: 100%;
  color: #fff;
}

h3 {
  font-weight: 700;
  font-size: 32px;
}

h4 {
  font-weight: 700;
  font-size: 120px;
  color: #fff;
}

h6 {
  position: relative;
  font-weight: 700;
  font-size: 24px;
  color: var(--red);
  text-transform: uppercase;
}

@media (max-width: 991px) {
  h2 {
    font-size: 32px;
  }
}

h6::before {
  content: '';
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateX(calc(-100% - 15px)) translateY(-50%);
  width: 100px;
  height: 2px;
  background: var(--red);
}

h6.extra-line::after {
  content: '';
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateX(calc(100% + 15px)) translateY(-50%);
  width: 100px;
  height: 2px;
  background: var(--red);
}

.centered h6::before {
  display: none;
}

.section-heading {
  max-width: 540px;
  margin-bottom: 60px;
}

.section-heading.centered {
  margin: 0 auto;
  text-align: center;
}

@media (max-width: 991px) {
  .section-heading.centered {
    text-align: left;
  }
}

.section-heading p {
  font-weight: 400;
  font-size: 20px;
  color: #fff;
  margin: 6px 0 0;
}

.section-heading h2 {
  margin: 12px 0;
}

.section-heading.dark-heading h2 {
  color: var(--dark-gray);
}

.section-heading.dark-heading p {
  color: var(--dark-gray);
}

/* Sections */
section:not(.mantine-Paper-root) {
  padding: 90px 0;
}

@media (max-width: 991px) {
  section:not(.mantine-Paper-root) {
    padding: 60px 0;
  }
}

.cta-section {
  background-size: cover;
  background-repeat: no-repeat;
  text-align: center;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.cta-section h4 {
  text-shadow: 0px 4px 8px rgba(0, 0, 0, 0.5);
  line-height: 100%;
  font-size: 70px; 
}

@media (max-width: 991px) {
  .cta-section h4 {
    font-size: 40px;
  }
}

.cta-section p {
  color: #fff;
  font-size: 24px;
  margin: 35px auto 40px;
  max-width: 550px;
}

/* Services */
.services {
  background-image: url('../public/img/services/services-bg.png');
  background-color: var(--dark-gray);
  background-repeat: no-repeat;
  background-size: cover;
}

.big-video {
  background-color: #ae0914;
}

.diensten {
  background-repeat: no-repeat;
  background-size: cover;
}

.service__card { 
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

@media (max-width: 991px) {
  .service__card {
    margin-bottom: 60px;
  }
}

.service__card img {
  max-width: 100%;
  margin-bottom: 35px;
}

.service__card h3 {
  color: #fff;
}

.service__card p {
  color: #fff;
}

/* Pricing */
.pricing-section {
  background: var(--light-gray);
}

.price__card {
  display: flex; 
  flex-direction: column;
  padding: 20px;
  border-radius: 6px;
  position: relative;
}

@media (max-width: 991px) {
  .price__card {
    margin-bottom: 60px;
  }
}

.price__card .label {
  position: absolute;
  top: -10px;
  right: 10px;
  padding: 5px 15px;
  border-radius: 6px;
  background: var(--dark-gray);
  color: #fff;
  font-size: 14px;
}

.price__card h4 {
  font-weight: 700;
  font-size: 28px;
  color: var(--dark-gray);
  margin: 0;
}

.price__card .pricing {
  display: flex;
  align-items: flex-end;
  margin: 15px 0;
}

.price__card .pricing span {
  font-weight: 700;
  font-size: 60px;
  line-height: 100%;
  color: var(--dark-gray);
}

.price__card .pricing span.frequency {
  font-weight: 700;
  font-size: 24px;
}

.price__card ul {
  padding: 0;
  padding-top: 25px;
  margin: 30px 0 20px;
  border-top: 2px solid var(--dark-gray);
}

.price__card ul li {
  margin-bottom: 20px;
  font-weight: 600;
  font-size: 16px;
  line-height: 100%;
  position: relative;
  padding-left: 30px;
}

.price__card ul li::before {
  content: url("data:image/svg+xml,%3Csvg width='17' height='16' viewBox='0 0 17 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.6851 14.3866C12.2453 15.862 10.3732 16.3081 9.31534 15.1895L1.38038 6.79944C0.322476 5.68086 0.872186 3.83654 2.36985 3.47966L13.6034 0.802827C15.101 0.445948 16.4234 1.84417 15.9836 3.31963L12.6851 14.3866Z' fill='%23251E20'/%3E%3C/svg%3E%0A");
  position: absolute;
  left: 0;
  top: calc(50% + 1px);
  transform: translateY(-50%);
}

.price__card.best-deal {
  background: var(--red);
}

.price__card.best-deal h4,
.price__card.best-deal p,
.price__card.best-deal .pricing span,
.price__card.best-deal ul li {
  color: #fff; 
}

.price__card.best-deal ul {
  border-top: 2px solid #fff;
}

.price__card.best-deal ul li::before {
  content: url("data:image/svg+xml,%3Csvg width='17' height='16' viewBox='0 0 17 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.6851 14.3866C12.2453 15.862 10.3732 16.3081 9.31534 15.1895L1.38038 6.79944C0.322476 5.68086 0.872186 3.83654 2.36985 3.47966L13.6034 0.802827C15.101 0.445948 16.4234 1.84417 15.9836 3.31963L12.6851 14.3866Z' fill='%23fff'/%3E%3C/svg%3E%0A");
}

/* Pulse */
.pulse {
  transform: scale(1);
  animation: pulse-red 2s infinite;
}

.pulse-gray {
  transform: scale(1);
  animation: pulse-dark-gray 2s infinite;
}

@keyframes pulse-dark-gray {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(37, 30, 32, .7);
  }
  
  70% {
    transform: scale(1.1);
    box-shadow: 0 0 0 10px rgba(37, 30, 32, 0);
  }
  
  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(37, 30, 32, 0);
  }
}

@keyframes pulse-red {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(204, 12, 25, .7);
  }
  
  70% {
    transform: scale(1.1);
    box-shadow: 0 0 0 10px rgba(204, 12, 25, 0);
  }
  
  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(204, 12, 25, 0);
  }
}

/* Modal */
.mantine-Modal-inner h2 {
  color: var(--dark-gray);
}

.mantine-Modal-inner p {
  font-size: 14px;
  margin: 0 0 15px;
}

.desktop {
  display: block;
}
.mobile {
  display: none;
}

@media (max-width: 991px) {
  .desktop {
    display: none;
  }
  .mobile {
    display: block;
  }
}
