.mantine-Text-root {
  line-height: 110% !important;
}

.header-review .subtext {
  line-height: 140% !important;
}

.nav {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  padding: 20px 0;
}

.nav .logo-image img {
  max-height: 60px;
  width: auto !important;
}

.nav .nav-item {
  white-space: nowrap;
}

.mantine-Text-root {
  letter-spacing: -0.5px;
}

/* Header */
.small-header {
  background: #151515;
  padding: 145px 0 90px;
}

/* CTA */
.call-to-action {
  padding-bottom: 0 !important;
  padding-top: 45px !important;
  position: relative;
  background-position: right;
  background-size: cover;
}

.call-to-action::after {
  content: '';
  background: rgba(21,21,21,0.925);
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;  
}

.cta-inner {
  position: relative;
  z-index: 10;
  width: 100%;
}

.cta-inner .section-heading {
  padding-bottom: 60px;
}