.insta-posts {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.insta-posts .insta-item {
  width: calc(20% - 10px); 
  position: relative; 
}

@media (max-width: 991px) {
  .insta-posts {
    row-gap: 20px;
  }
  .insta-posts .insta-item {
    width: calc(50% - 10px);
  }
}

.inta-posts .insta-item a {
  display: flex;
  max-width: 100%;
}

.insta-posts .insta-item img {
  width: 100%;
  display: flex;
}

.insta-posts .insta-img {
  width: 24px !important;
}

.insta-item .insta-overlay {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(205, 12, 25, 0.75);
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
}

.insta-item:hover .insta-overlay {
  opacity: 1;
  transition: opacity .5s ease;
}