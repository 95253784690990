#video-container {
  position: fixed;
  top: auto;
  right: 2.5%;
  bottom: 2.5%;
  width: 200px;
  height: 306px;
  transition: width 0.5s ease, height 0.5s ease, bottom 0.5s ease, right 0.5s ease;
  z-index: 999;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

#video-container.minimize {
  width: 50px;
  height: 76px;
}

#video-container.modal {
  position: fixed;
  top: auto;
  bottom: 50%;
  right: 50%;
  transform: translate(50%, 50%);
  width: 400px;
  height: 600px;
  object-fit: cover;
  border-radius: 12px;
  border: 3px solid var(--red);
  transition: width 0.5s ease, height 0.5s ease, bottom 0.5s ease, right 0.5s ease;
}

#video-container .overlay {
  width: 100%;
  height: 80%;
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: 999;
  cursor: pointer;
  display: flex;
}

#video-container.modal .overlay {
  display: none;
}

#video-container video {
  position: relative;
  width: 200px;
  height: 300px;
  object-fit: cover;
  border-radius: 12px;
	transform: scale(1);
	animation: pulse 1.5s infinite;
}

.play-video-button {
  position: absolute;
  left: 50%;
  top: 20px;
  transform: translateY(-100%) translateX(-50%);
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  white-space: nowrap;
  background: var(--red);
  padding: 3.5px 10px 27.5px;
  border-radius: 6px 6px 0 0;
  box-shadow: 0 0 8px rgba(0,0,0,0.2);
}

.play-video-button svg {
  width: 15px;
  height: auto;
  position: relative;
  top: 2.5px;
}

@keyframes pulse {
	0% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.7);
	}

	70% {
		transform: scale(1);
		box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
	}

	100% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
	}
}

#video-container.minimize video {
  width: 50px;
  height: 76px;
  animation: none;
}

#video-container.modal video {
  position: relative;
  width: 400px;
  height: 600px;
  object-fit: cover;
  border-radius: 12px;
  animation: none;
}

@media (max-width: 991px) {
  #video-container {
    right: 5.5%;
    bottom: 5.5%;
  }
  #video-container.modal {
    width: 320px;
    height: 480px;
  }
  #video-container.modal video {
    width: 320px;
    height: 480px;
    max-width: 100vw !important;
    max-height: 100vh !important;
    object-fit: contain;
  }
}

.close-icon {
  position: absolute;
  right: 5px;
  bottom: 13px;
  top: auto;
  z-index: 999;
  width: 30px;
  height: 30px;
  cursor: pointer;
  transform: rotate(180deg);
  transition: transform .5s ease;
}

.close-icon.active {
  position: absolute;
  right: 4px;
  bottom: auto;
  top: 8px;
  z-index: 999;
  width: 30px;
  height: 30px;
  cursor: pointer;
  transform: rotate(0deg);
  transition: transform .5s ease;
}

.minimize-icon {
  position: absolute;
  right: 10px;
  top: 10px;
  z-index: 999;
  width: 30px;
  height: 30px;
  cursor: pointer;
}

.minimize-icon.active {
  position: absolute;
  right: 4px;
  bottom: auto;
  top: 8px;
  z-index: 999;
  width: 30px;
  height: 30px;
  cursor: pointer;
  transform: rotate(0deg);
  transition: transform .5s ease;
}

.cta-overlay {
  position: fixed;
  left: 0;
  top: -500%;
  width: 100vw;
  height: 100vh;
  background: rgba(204, 12, 25, 1);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 5;
  opacity: 0;
  transition: opacity .5s ease;
}

.cta-overlay.active {
  top: 0%;
  opacity: 1;
  transition: opacity .5s ease;
  overflow-y: auto;
  padding-bottom: 30px;
}

.cta-overlay h2 {
  color: #fff;
  font-size: 70px;
  font-weight: 900;
  margin-bottom: 30px;
}

.cta-overlay .cta-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.cta-overlay .cta-content p {
  color: #fff;
  font-size: 18px;
  margin-bottom: 15px;
}

.cta-overlay .cta-content input {
  padding: 30px;
  background: rgba(37, 30, 32, 0.5);
  border: none;
  box-shadow: none;
  color: #fff;
  font-size: 24px;
  border-radius: 8px;
  margin-bottom: 25px;
  width: 90%;
}

@media (max-width: 991px) {
  .cta-overlay {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    padding-top: 120px;
  }
  .cta-overlay h2 {
    font-size: 31px;
    text-align: center;
  }
  .cta-overlay .cta-content .close-icon.active {
    right: 20px !important;
    top: 20px !important;
  }
  .cta-overlay .cta-content input {
    padding: 20px;
    font-size: 18px;
  }
}