.text-section {
  color: #fff;
  text-align: left;
}

.herkenning-section {
  color: #000;
}

.text-section h3 {
  margin-bottom: 20px;
  line-height: 40px;
}

.text ul li {
  margin-bottom: 20px;
  font-weight: 300;
  font-size: 21px;
  line-height: 150%;
  position: relative;
  padding-left: 0;
  color: #fff;
}

.herkenning-section.text ul li {
  color: #000;
}

.text ul li b {
  font-weight: 700;
}

.text ul li::before {
  content: url("data:image/svg+xml,%3Csvg width='17' height='16' viewBox='0 0 17 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.6851 14.3866C12.2453 15.862 10.3732 16.3081 9.31534 15.1895L1.38038 6.79944C0.322476 5.68086 0.872186 3.83654 2.36985 3.47966L13.6034 0.802827C15.101 0.445948 16.4234 1.84417 15.9836 3.31963L12.6851 14.3866Z' fill='%23182022'/%3E%3C/svg%3E%0A");
  position: absolute;
  left: 0;
  top: calc(50% + 1px);
  transform: translateY(-50%);
  display: none;
}