#video-container {
  position: fixed;
  top: auto;
  right: 2.5%;
  bottom: 2.5%;
  width: 200px;
  height: 306px;
  transition: width 0.5s ease, height 0.5s ease, bottom 0.5s ease, right 0.5s ease;
  z-index: 999;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

#video-container.minimize {
  width: 50px;
  height: 76px;
}

#video-container.modal {
  position: fixed;
  top: auto;
  bottom: 50%;
  right: 50%;
  transform: translate(50%, 50%);
  width: 400px;
  height: 600px;
  object-fit: cover;
  border-radius: 12px;
  border: 3px solid var(--red);
  transition: width 0.5s ease, height 0.5s ease, bottom 0.5s ease, right 0.5s ease;
}

#video-container .overlay {
  width: 100%;
  height: 80%;
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: 999;
  cursor: pointer;
  display: flex;
}

#video-container.modal .overlay {
  display: none;
}

#video-container video {
  position: relative;
  width: 200px;
  height: 300px;
  object-fit: cover;
  border-radius: 12px;
	transform: scale(1);
	animation: pulse 1.5s infinite;
}

.play-video-button {
  position: absolute;
  left: 50%;
  top: 20px;
  transform: translateY(-100%) translateX(-50%);
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  white-space: nowrap;
  background: var(--red);
  padding: 3.5px 10px 27.5px;
  border-radius: 6px 6px 0 0;
  box-shadow: 0 0 8px rgba(0,0,0,0.2);
}

.play-video-button svg {
  width: 15px;
  height: auto;
  position: relative;
  top: 2.5px;
}

@keyframes pulse {
	0% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.7);
	}

	70% {
		transform: scale(1);
		box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
	}

	100% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
	}
}

#video-container.minimize video {
  width: 50px;
  height: 76px;
  animation: none;
}

#video-container.modal video {
  position: relative;
  width: 400px;
  height: 600px;
  object-fit: cover;
  border-radius: 12px;
  animation: none;
}

@media (max-width: 991px) {
  #video-container {
    right: 5.5%;
    bottom: 5.5%;
  }
  #video-container.modal {
    width: 320px;
    height: 480px;
  }
  #video-container.modal video {
    width: 320px;
    height: 480px;
    max-width: 100vw !important;
    max-height: 100vh !important;
    object-fit: contain;
  }
}

.close-icon {
  position: absolute;
  right: 5px;
  bottom: 13px;
  top: auto;
  z-index: 999;
  width: 30px;
  height: 30px;
  cursor: pointer;
  transform: rotate(180deg);
  transition: transform .5s ease;
}

.close-icon.active {
  position: absolute;
  right: 4px;
  bottom: auto;
  top: 8px;
  z-index: 999;
  width: 30px;
  height: 30px;
  cursor: pointer;
  transform: rotate(0deg);
  transition: transform .5s ease;
}

.minimize-icon {
  position: absolute;
  right: 10px;
  top: 10px;
  z-index: 999;
  width: 30px;
  height: 30px;
  cursor: pointer;
}

.minimize-icon.active {
  position: absolute;
  right: 4px;
  bottom: auto;
  top: 8px;
  z-index: 999;
  width: 30px;
  height: 30px;
  cursor: pointer;
  transform: rotate(0deg);
  transition: transform .5s ease;
}

.cta-overlay {
  position: fixed;
  left: 0;
  top: -500%;
  width: 100vw;
  height: 100vh;
  background: rgba(204, 12, 25, 1);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 5;
  opacity: 0;
  transition: opacity .5s ease;
}

.cta-overlay.active {
  top: 0%;
  opacity: 1;
  transition: opacity .5s ease;
  overflow-y: auto;
  padding-bottom: 30px;
}

.cta-overlay h2 {
  color: #fff;
  font-size: 34px;
  font-weight: 900;
  margin-bottom: 30px;
  
}

.cta-overlay .cta-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.cta-overlay .cta-content p {
  color: #fff;
  font-size: 18px;
  margin-bottom: 15px;
}

.cta-overlay .cta-content input {
  padding: 30px;
  background: rgba(37, 30, 32, 0.5);
  border: none;
  box-shadow: none;
  color: #fff;
  font-size: 24px;
  border-radius: 8px;
  margin-bottom: 25px;
  width: 90%;
}

@media (min-width: 992px) {
  .cta-content {
    max-width: 600px;
  }
  .cta-overlay h2 {
    text-align: center;
    line-height: 48px;
  }
}

@media (max-width: 991px) {
  .cta-overlay {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    padding-top: 60px;
  }
  .cta-overlay h2 {
    font-size: 27px;
    text-align: center;line-height: 38px;
  }
  .cta-overlay .cta-content .close-icon.active {
    right: 20px !important;
    top: 20px !important;
  }
  .cta-overlay .cta-content input {
    padding: 20px;
    font-size: 18px;
  }
}

.result-list {
  margin-top: 15px;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.result-list li {
  display: flex;
  align-items: center;
  gap: 12px;
  font-size: 14px;
}

.result-list li svg {
  width: 20px;
  height: auto;
  position: relative;
  top: 1px;
}

.cta-overlay h5 {
  color: #fff;
  text-align: center;
}

.input-cont {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.input-cont .input-error {
  position: absolute;
  bottom: 25px;
  width: 100%;
  left: calc(50% + 20px);
  transform: translateX(-50%);
  font-size: 12px;
  color: #fff;
}

.input-cont.error input {
  margin-bottom: 50px;
}












.reviews-section {
  padding: 90px 0 70px;
}

@media (max-width: 991px) {
  .reviews-section {
    padding: 45px 0 35px;
  }
}

.reviews-heading {
  border-bottom: 1px solid #E3E3E2;
  padding-bottom: 30px;
}

.reviews-heading h4 {
  color: rgba(255,255,255,0.75);
  font-size: 45px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px; /* 48.889% */
  letter-spacing: 0.9px;
  text-decoration-line: underline;
  text-transform: uppercase;
  margin-bottom: 45px;
}

@media (max-width: 991px) {
  .reviews-heading h4 {
    font-size: 27px;
    font-style: normal;
    font-weight: 700;
    line-height: 22px; /* 81.481% */
    letter-spacing: 0.54px;
    text-decoration-line: underline;
    text-transform: uppercase;
    margin: 0 auto;
    text-align: center;
  }
}

.stars-rating {
  margin-top: 30px;
  display: flex;
  align-items: center;
  gap: 12px;
  color: #fff;
  font-size: 14.5px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px; /* 88% */
  letter-spacing: 1.25px;
  text-transform: capitalize;
}

@media (max-width: 991px) {
  .stars-rating {
    justify-content: center;
  }
}

.stars-rating img {
  width: 120px;
  position: relative;
  top: 0px;
}

.review-title {
  color: #fff;
  font-family: "Neue Haas Grotesk Display Pro";
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px; /* 111.111% */
  letter-spacing: -0.54px;
  margin-top: 35px;
  margin-bottom: -25px;
}

.stars-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.stars-row span {
  color: #9D9D9D;
  font-family: "Neue Haas Grotesk Display Pro";
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px; /* 150% */
  letter-spacing: -0.4px;
}

.stars-row > img {
  max-width: 120px;
}


.review-swiper {
  padding-bottom: 90px;
}

.review-swiper .swiper-pagination-bullet {
  width: 22px;
  height: 22px;
  margin: 0 8.5px !important;
  background: #C4C4C4 !important;
}

@media (max-width: 991px) {
  .review-swiper .swiper-pagination-bullet {
    width: 17px;
    height: 17px;
  }
}

.review-swiper .swiper-pagination-bullet-active {
  background: #233918 !important;
}

.review-items {
  display: flex;
  flex-direction: column;
}

.review-item {
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding: 30px;
  border-bottom: 1px solid #E3E3E2;
} 

@media (min-width: 992px) {
  .review-item p {
    width: 80%;
  }
}

.review-item p {
  color: rgba(255,255,255,0.9);
}

.review-item > img {
  width: 120px;
}

.review-item .review-name {
  display: flex;
  align-items: center;
  gap: 10px;
  color: #9D9D9D;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px; /* 150% */
  letter-spacing: -0.4px;
}

@media (max-width: 991px) {
  .review-item .review-name {
    font-size: 16px;
  }
}

.review-item h5 {
  color: #fff;
  font-size: 26px;
  font-style: normal;
  font-weight: 700;
  line-height: 30px; /* 100% */
  letter-spacing: -0.5px;
  margin: 8px 0 0;
}

@media (max-width: 991px) {
  .review-item h5 {
    margin-top: 0;
  }
}

.review-item .review-name .verified-badge {
  display: flex;
  align-items: center;
  gap: 8.5px;
  color: #eee;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px; /* 187.5% */
  letter-spacing: 0.32px;
}

.review-item .review-name .verified-badge img {
  width: 20px;
  height: auto;
}

@media (max-width: 991px) {
  .review-item .review-name .verified-badge {
    font-size: 13px;
    gap: 4px;
  }
}