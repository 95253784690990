.foto-img {
  position: relative;
}

.foto-img {
  width: 50% !important;
}

.foto-img img {
  width: 100% !important;
  max-height: 70vh;
  object-fit: cover;
}

@media (min-width: 992px) {
  .foto-img img {
    height: 410px; 
  }
}

@media (max-width: 991px) {
  .foto-img {
    display: flex;
    align-items: center;
  }
  .foto-img img {
    height: 400px; 
    max-height: none !important;
    object-position: inherit !important;
  }
}

.foto-imgs {
  display: flex;
  align-items: center;
}

/* .foto-img img:nth-child(1) {
  position: relative;
  top: -45px;
  left: -45px;
}

.foto-img img:nth-child(2) {
  position: absolute;
  bottom: -45px;
  right: -45px;
} */

.arrow-pointer {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 5; 
  content: url("data:image/svg+xml,%3Csvg width='332' height='24' viewBox='0 0 332 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M331.061 13.0607C331.646 12.4749 331.646 11.5251 331.061 10.9393L321.515 1.3934C320.929 0.807611 319.979 0.807611 319.393 1.3934C318.808 1.97919 318.808 2.92893 319.393 3.51472L327.879 12L319.393 20.4853C318.808 21.0711 318.808 22.0208 319.393 22.6066C319.979 23.1924 320.929 23.1924 321.515 22.6066L331.061 13.0607ZM0 13.5H330V10.5H0V13.5Z' fill='%23CC0C19'/%3E%3C/svg%3E%0A");
}

@media (max-width: 991px) {
  .arrow-pointer {
    transform: translate(-50%, -50%) rotate(90deg);
  }
}



.ba-section ul {
  margin: 25px 0;
  padding: 0;
}

.ba-section ul li {
  color: #fff;
  margin-bottom: 6px;
}

/* .ba-section .button {
  padding: 20px 30px 30px;
} */

.ba-section p {
  font-weight: 500 !important;
  margin: 0 auto;
  color: #fff;
}