.prijzen {
  background: var(--light-gray);
  padding: 90px 0 90px !important;
}

@media (max-width: 991px) {
  .prijzen {
    padding-bottom: 0 !important;
  }
  .prijs-timer {
    margin-top: 0 !important;
  }
}

.prijzen .section-heading h2 {
  color: #fff !important;
}

.prijzen .section-heading p {
  color: #fff;
}

.prijs-cart {
  display: flex; 
  flex-direction: column;
  padding: 20px;
  border-radius: 6px;
  position: relative;
}

@media (max-width: 991px) {
  .prijs-cart {
    margin-bottom: 60px;
  }
}

.prijs-cart .label {
  position: absolute;
  top: -10px;
  right: 10px;
  padding: 5px 15px;
  border-radius: 6px;
  background: var(--dark-gray);
  color: #fff;
  font-size: 14px;
}

.prijs-cart h4 {
  font-weight: 700;
  font-size: 28px;
  line-height: 100%;
  color: #fff;
  margin: 0;
}

.prijs-cart .pricing {
  display: flex;
  align-items: flex-end;
  margin: 15px 0;
}

.prijs-cart .pricing span {
  font-weight: 700;
  font-size: 60px;
  line-height: 100%;
  color: #fff;
}

.prijs-cart .pricing span.old-price {
  font-weight: 500;
  font-size: 28px;
  text-decoration: line-through;
  margin-left: 10px;
  margin-bottom: 4px;
}

.prijs-cart p {
  color: #fff;
  font-weight: 400;
  font-size: 18px;
  line-height: 130%;
}

.prijs-cart ul {
  padding: 0;
  padding-top: 25px;
  margin: 30px 0 20px;
  border-top: 2px solid #fff;
}

.prijs-cart .sticker {
  width: 125px;
  height: 125px;
  position: absolute;
  right: -10px;
  top: -40px;
  border-radius: 50%;
  transform: rotate(16deg);
  background: #353535;
  color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.prijs-cart .sticker.extra {
  width: 150px; 
  height: 150px;
  right: -65px;
  top: 57px;
  background: #fff;
  color: #353535;
  transform: rotate(24deg);
}

@media (max-width: 991px) {
  .prijs-cart .sticker.extra {
    right: -5px;
    top: 39px;
  }
}

.prijs-cart .sticker span:first-child {
  font-weight: 700;
  font-size: 34px;
  line-height: 32px;
  position: relative;
  top: -5px;
}

.prijs-cart .sticker span:last-child {
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  position: relative;
  top: -5px;
}

.prijs-cart .sticker.extra span:first-child {
  font-weight: 600;
  font-size: 19px;
  line-height: 32px;
  position: relative;
  top: -5px;
}

.prijs-cart .sticker.extra span:nth-child(2) {
  font-weight: 900;
  font-size: 22px;
  line-height: 22px;
  position: relative;
  top: -7.5px;
}

.prijs-cart .sticker.extra span:nth-child(3) {
  font-weight: 900;
}

.prijs-cart ul li {
  margin-bottom: 20px;
  font-weight: 500;
  font-size: 20px;
  line-height: 156%;
  position: relative;
  padding-left: 30px;
  color: #fff;
}

@media (max-width: 991px) {
  .prijs-cart ul li {
    margin-bottom: 15px;
    font-weight: 500;
    font-size: 16px;
    line-height: 135%;
    position: relative;
    padding-left: 30px;
    color: #fff;
  }
}

.prijs-cart ul li b {
  font-weight: 700;
}

.prijs-cart small {
  color: #fff;
}

.prijs-cart ul li::before {
  content: url("data:image/svg+xml,%3Csvg width='17' height='16' viewBox='0 0 17 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.6851 14.3866C12.2453 15.862 10.3732 16.3081 9.31534 15.1895L1.38038 6.79944C0.322476 5.68086 0.872186 3.83654 2.36985 3.47966L13.6034 0.802827C15.101 0.445948 16.4234 1.84417 15.9836 3.31963L12.6851 14.3866Z' fill='%23999'/%3E%3C/svg%3E%0A");
  position: absolute;
  left: 0;
  top: 15px;
  transform: translateY(-50%) rotate(42.5deg);
}

.prijs-cart.best-deal {
  background: var(--red);
}

.prijs-cart h6 {
  margin-bottom: 10px;
}

.prijs-cart.best-deal h6::before {
  display: none;
}

.prijs-cart.best-deal h4,
.prijs-cart.best-deal p,
.prijs-cart.best-deal .pricing span,
.prijs-cart.best-deal ul li {
  color: #fff; 
}

.prijs-cart.best-deal ul {
  border-top: 2px solid #fff;
}

.prijs-cart.best-deal ul li::before {
  content: url("data:image/svg+xml,%3Csvg width='17' height='16' viewBox='0 0 17 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.6851 14.3866C12.2453 15.862 10.3732 16.3081 9.31534 15.1895L1.38038 6.79944C0.322476 5.68086 0.872186 3.83654 2.36985 3.47966L13.6034 0.802827C15.101 0.445948 16.4234 1.84417 15.9836 3.31963L12.6851 14.3866Z' fill='%23fff'/%3E%3C/svg%3E%0A");
}

.prijs-timer {
  margin-top: 45px;
  position: relative;
}