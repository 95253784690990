.footer {
  background: #333;
  color: #eee;
  padding: 55px 0 20px;
}

@media (max-width: 991px) {
  .footer {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

.button-container {
  display: flex;
  flex-direction: row;
  gap: 20px;
}

.button-container button {
  white-space: nowrap;
}

@media (max-width: 991px) {
  .button-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: stretch;
    gap: 20px;
  }
}

.footer a svg {
  width: auto;
  max-height: 70px;
}

.footer .stars-rating {
  margin-top: 0;
}

.footer .stars-rating span {
  position: relative;
  top: 2.5px;
  color: #eee;
}

.footer .socials-insta {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
}

.footer .socials-insta svg {
  margin: 0;
}

.email-icon {
  width: 24px !important;
  height: 24px;
  position: relative;
  top: 4px;
}

.footer .footer-logo {
  display: flex;
  align-items: center;
  justify-content: center;
}

.copyright-footer {
  border-top: 1px solid rgba(255,255,255,0.1);
  padding-top: 20px;
  margin-top: 45px;
  display: flex;
}

@media (max-width: 991px) {
  .copyright-footer {
    font-size: 13px;
  }
  .footer .footer-logo {
    margin-top: 50px;
    margin-bottom: 20px
  }
  .footer .socials-insta {
    justify-content: center;
  }
}