.prijs-card ul li {
  font-size: 14px;
  margin-bottom: 30px !important;
}

@media (max-width: 991px) {
  .prijs-card {
    padding: 0;
    margin: 30px 0;
  }
}

.prijs-card ul {
  border: none;
}

.prijs-card ul li svg {
  margin-right: 20px;
  width: 30px;
  min-width: 30px;
  top: -1.5px;
}

.prijs-card ul li .list-icon {
  margin-right: 20px;
  width: 30px;
  min-width: 30px;
  top: -1.5px;
  font-size: 27px;
}

.prijs-card .pricing span {
  font-size: 100px;
}

.prijs-card .pricing span.old-price {
  font-size: 62px;
}

@media (max-width: 991px) {
  .prijs-card .pricing span {
    font-size: 60px;
  }
  
  .prijs-card .pricing span.old-price {
    font-size: 32px;
  }
}

.prijs-card p {
  font-size: 21px;
  line-height: 170%;
}

@media (max-width: 991px) {
  .prijs-card p {
    font-size: 16px;
  }
}

.offer-content h4 {
  margin-bottom: 10px;
  font-size: 24px;
}



.offer-quiz {
  padding: 30px 30px 45px;
  border-radius: 20px;
  background-color: #eee;
}

@media (max-width: 991px) {
  .offer-quiz {
    padding: 30px 20px 30px;
    max-width: 92vw;
  }
}

.quiz-subject {
  max-height: 0px;
  text-align: center;
  overflow: hidden;
}

.quiz-subject h4 {
  text-align: center;
  color: #000;
  font-size: 32px;
  margin: 0 0 10px;
}

.quiz-subject button {
  margin: 0 10px;
}

@media (max-width: 991px) {
  .quiz-subject button {
    padding: 12px 24px;
  }
}

.quiz-subject.active {
  max-height: 1000px;
}

.quiz-answers {
  display: flex;
  gap: 10px;
  justify-content: center;
  margin-top: 20px;
}

@media (max-width: 991px) {
  .quiz-answers {
    flex-direction: column;
  }
}

.quiz-answers .quiz-answer {
  padding: 12px 24px;
  border-radius: 6px;
  border: 1px solid #fff; 
  background-color: #fff;
  box-shadow: 0 0 8px rgba(0,0,0,0.0);
  transition: all .5s ease;
  cursor: pointer;
}

.quiz-answers .quiz-answer:hover {
  background-color: var(--red);
  color: #fff;
  transition: all .5s ease;
}

.quiz-answers input {
  color: #000;
  background-color: #fff;
}

.quiz-answers textarea {
  color: #000;
  background-color: #fff;
  padding: 30px;
  border: none;
  box-shadow: none;
  font-size: 24px;
  border-radius: 8px;
  margin-bottom: 25px;
  font-family: 'Sarabun', sans-serif;
}

@media (max-width: 991px) {
  .quiz-answers input,
  .quiz-answers textarea {
    font-size: 16px;
  }
}


.step-lines {
  display: flex;
  flex-direction: column;
  border-left: 3px solid rgba(0,0,0,0.1);
}

@media (max-width: 991px) {
  .step-lines {
    margin-left: 20px;
  }
}

.step-line {
  position: relative;
  padding: 30px 0 30px 45px;
}

.step-line .line-number {
  content: '';
  position: absolute;
  left: -1.5px;
  transform: translate(-50%, -50%);
  top: 50%;
  width: 30px;
  height: 30px;
  background-color: var(--red);
  color: #fff;
  font-size: 16px;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50px;
}




.traject .step-line h5 {
  font-size: 18px !important;
  margin: 0 0 7px;
}

.traject ul li {
  list-style: initial;
  font-size: 14px;
  margin: 0 0 6px;
}




.pakket-section {
  position: relative;
}

.pakket-section .offer-sticker {
  position: absolute;
  top: 0;
  right: 15px;
  border-radius: 50%;
  background: var(--red);
  color: #fff;
  width: 120px;
  height: 120px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transform: translateY(-30%) rotate(15deg);
  white-space: nowrap;
  z-index: 100;
}

.pakket-section .offer-sticker > span {
  line-height: 100%;
  font-size: 15px;
}

.pakket-section .offer-sticker > span:nth-child(2) {
  font-weight: bold;
  font-size: 17.5px;
  margin: 7px 0 6px;
}

@media (min-width: 992px) {
  .pakket-section .offer-sticker {
    position: absolute;
    top: 0;
    right: 250px;
    border-radius: 50%;
    background: var(--red);
    color: #fff;
    width: 180px;
    height: 180px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    transform: translateY(-30%) rotate(15deg);
    white-space: nowrap;
    z-index: 100;
  }
  .pakket-section .offer-sticker > span {
    line-height: 100%;
    font-size: 21px;
  }
  
  .pakket-section .offer-sticker > span:nth-child(2) {
    font-weight: bold;
    font-size: 24px;
    margin: 7px 0 6px;
  }
}



.pakket-section h2 {
  font-size: 72px;
  letter-spacing: -2px;
  font-weight: 900;
  text-transform: uppercase;
  color: #000;
}

@media (max-width: 991px) {
  .pakket-section h2 {
    font-size: 42px;
  }
}

.button-container.sticky-button {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100vw;
  padding: 15px;
  z-index: 999;
  background-color: #fff;
  gap: 2px;
  box-shadow: 0 0 8px rgba(0,0,0,0.2);
}

.button-container.sticky-button small {
  display: none;
}

.button-container.sticky-button .betalen-desc {
  font-size: 13px;
}


@media (min-width: 992px) {
  .coach-section h2 {
    font-size: 52px !important;
  }
}

.offer-items p {
  font-size: 14px;
}


footer {
  padding-bottom: 205px !important;
}

@media (max-width: 991px) {
  .offer-img img {
    max-width: 300px
  }
}



.offer-simple-list li {
  margin: 0 !important;
  flex-direction: column;
  display: flex;
  margin-bottom: 20px !important;
} 

.offer-simple-list li p {
  font-size: 16px;
  margin-left: 0;
  display: none;
}

.offer-simple-list li span {
  font-size: 21px !important;
  margin-bottom: 1.5px !important;
  display: flex !important;
  gap: 10px !important;
}

@media (max-width: 991px) {
  .offer-simple-list li > span {
    font-size: 20px !important;
    margin-bottom: 15px !important;
    line-height: 145% !important;
    display: flex !important;
    justify-content: space-between !important;
    gap: 10px !important;
    width: 100%;
  }
}

.offer-simple-list li span span {
  color: var(--red);
}




.pricing-items span {
  font-size: 32px;
}

.pricing-items > span:nth-child(1) {
  font-size: 21px;
}

.pricing-items > span:nth-child(1) span {
  font-size: 24px;
  margin-left: 5px;
}

.pricing-items > span:nth-child(2) span {
  font-size: 40px;
  margin-left: 10px;
  font-weight: 600;
}

@media (max-width: 991px) {
  .pricing-items span {
    font-size: 24px;
  }
  .pricing-items > span:nth-child(1) {
    font-size: 18px;
  }

  #offer .button-container button {
    white-space: wrap;
    line-height: 28px;
    padding: 14px 36px 18px;
  }
}



@media (max-width: 991px) {
  .offer-price {
    font-size: 30px !important;
  }
}